<template>
  <div class="pageview">
    <!-- <van-nav-bar title="便民服务" fixed /> -->
    <div class="bgcolor"></div>
    <!-- banner切换 -->
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs" :key="banner"
        ><img :src="banner" style="height: 172px" />
      </van-swipe-item>
    </van-swipe>
    <div style="margin: 10px auto; border-radius: 5px; width: 95%">
      <div style="border-radius: 5px; background: #ffff">
        <!-- <h3 class="grouptitle">便民服务</h3> -->
        <van-grid class="wktype" :border="false" :square="true">
          <template>
            <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar')">
              <van-image
                class="iconimg"
                :src="require('../../assets/五星党建.png')"
              ></van-image>
              <span class="wktext">五星党建</span>
            </van-grid-item>
          </template>
          <van-grid-item icon="photo-o" @click="gotoUrl('/work')">
            <van-image
              class="iconimg"
              :src="require('../../assets/网上办事.png')"
            ></van-image>
            <span class="wktext">网上办事</span>
          </van-grid-item>
          <van-grid-item icon="photo-o" @click="gotoUrl('/activity')">
            <van-image
              class="iconimg"
              :src="require('../../assets/社区活动.png')"
            ></van-image>
            <span class="wktext">社区活动</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/article')">
            <van-image class="iconimg"
              :src="require('../../assets/zixun.png')"></van-image>
            <span class="wktext">社区资讯</span>
          </van-grid-item> -->
          <van-grid-item icon="photo-o" @click="gotoUrl('/volunteerAct')">
            <van-image
              class="iconimg"
              :src="require('../../assets/志愿者活动.png')"
            ></van-image>
            <span class="wktext">志愿者活动</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="gotoUrl('/partymember')">
            <van-image class="iconimg"
              :src="require('../../assets/智慧党建.png')"></van-image>
            <span class="wktext">智慧党建</span>
          </van-grid-item> -->
          <!-- <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/scenery')">
          <van-image class="iconimg"
            :src="require('../../assets/shequfengcai.png')"></van-image>
          <span class="wktext">社区风采</span>
        </van-grid-item>
      </template> -->
          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/village')">
          <van-image class="iconimg"
            :src="require('../../assets/shijianyinhang.png')"></van-image>
          <span class="wktext">我的社区(村)</span>
        </van-grid-item>
      </template> -->
          <template>
            <van-grid-item icon="photo-o" @click="gotoUrl('/ylOrder')">
              <van-image
                class="iconimg"
                :src="require('../../assets/医康养护.png')"
              ></van-image>
              <span class="wktext">医康养护</span>
            </van-grid-item>
          </template>
          <van-grid-item icon="photo-o" @click="gotoUrl('/list')">
            <van-image
              class="iconimg"
              :src="require('../../assets/jiuye.png')"
            ></van-image>
            <span class="wktext">求职招聘</span>
          </van-grid-item>

          <template>
            <van-grid-item icon="photo-o" @click="gotoUrl('/register')">
              <van-image
                class="iconimg"
                :src="require('../../assets/yiqing.png')"
              ></van-image>
              <span class="wktext">疫情防控</span>
            </van-grid-item>
          </template>
          <!-- <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotominiProgram('shijianyinhang')">
          <van-image class="iconimg"
            :src="require('../../assets/shijianyinhang.png')"></van-image>
          <span class="wktext">时间银行</span>
        </van-grid-item>
      </template> -->
          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/notice')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">通知公告</span>
        </van-grid-item>
      </template> -->
          <!-- <template>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/list')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">求职招聘</span>
        </van-grid-item>
      </template> -->
          <van-grid-item icon="photo-o" @click="gotoUrl('/shop')">
            <van-image
              class="iconimg"
              :src="require('../../assets/便民网点.png')"
            ></van-image>
            <span class="wktext">惠民活动</span>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <!-- <div>
      <router-link to="/register">
        <img style="width:90%;margin:8px 5%"
          src="../../assets/dengji.png"
          alt="">
      </router-link>
    </div> -->
    <!-- <br class="brakline" /> -->
    <div style="width: 95%; margin: 15px auto">
      <div class="essay" style="margin: 0; width: 100%; padding: 0">
        <div
          class="essayContent"
          style="
            padding: 0;
            width: 100%;
            box-shadow: 0px 0px 4px #e7e1e1;
            border-radius: 11px;
          "
          v-html="appInfo.WorkTime"
        ></div>
      </div>
    </div>
    <!-- <div style="border-left: 7px solid #eee;border-right: 7px solid #eee;border-bottom: 7px solid #eee;border-top: 1px solid #eee;">
      <h3 class="grouptitle">工作时间</h3>
      <div class="information">
        <div class="center"
          style="border:0px;line-height: 10px;padding: 4px 15px;">
          <div v-html="appInfo.WorkTime"></div>
        </div>
      </div>
    </div> -->
    <div
      style="
        background: #fff;
        margin: 0 auto 10px;
        width: 95%;
        border-radius: 5px;
      "
    >
      <div style="padding-top: 15px; display: flex; align-items: center">
        <img src="../../assets/五星.png" alt="" width="16px" />
        <div class="grouptitle">五星支部创建</div>
      </div>
      <div class="information">
        <van-empty description="暂无消息" v-if="!NoticeList.length" />
        <div class="center" v-for="item in NoticeList" :key="item" v-else>
          <div @click="gotoUrl('/fivestar/wuXingXq/' + item.AId)">
            <div
              class="Content"
              style="border-bottom: 1px solid rgb(245 243 243)"
            >
              <div class="ContentB">
                <div class="centerTitle van-ellipsis" style="font-size: 16px">
                  {{ item.Title }}
                </div>
                <span
                  class="centertime van-multi-ellipsis--l2"
                  style="margin: 5px 0px; font-size: 13px; color: #666666"
                  >{{ item.Outline }}</span
                >
                <span class="centertime" style="color: #999999"
                  >{{ item.StartTime | timeFilter }}
                </span>
              </div>
              <div
                style="display: inline-block; width: 33%"
                class="ContentA"
                ref="getHeight"
              >
                <img v-if="item.AImgUrl" :src="item.AImgUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index";
import {
  WeGetRealInfoPage,
  WePBGetRealInfoPage,
  WeGetMyActivityPage,
} from "@/api/RealInfo";
import { WeGetAppletOrganList } from "@/api/Organ";
import { WeGetAppletInfo } from "@/api/index";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      NoticeList: [],
      area: "",
      OrderName: "太康东路街道办事处",
      OrderShow: false, //社区列表显示
      OrderList: [], //社区列表
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  created() {
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    this.getNoticeList("书记茶话会");
    this.getNoticeList("多元化社区");
    this.getNoticeList("社群e家联防巡逻");
    this.getNoticeList("爱心大妈");
    this.getNoticeList("餐饮专题培训");
  },
  methods: {
    // 点击社区列表
    OrderShowClick() {
      WeGetAppletOrganList().then((res) => {
        this.OrderShow = true;
        this.OrderList = res.data.data;
      });
    },
    // 选择社区
    OrderClick(row){
      console.log(row);
      
    },  
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 通知消息 五星支部创建
    getNoticeList(row) {
      WeGetMyActivityPage({
        taskClassification: row,
        page: 1,
        limit: 1,
        openid: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          this.NoticeList.push(res.data.data[0]);
          if (res.data.data.length != 0) {
            for (var i = 0; i < this.NoticeList.length; i++) {
              var item = this.NoticeList[i].IssTime;
              item = this.dataFormat(item);
              this.NoticeList[i].IssTime = item;
            }
          }
        }
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.ContentB {
  display: inline-block;
  width: 59% !important;
  margin-right: 6%;
  margin-left: 0% !important;
}

.bgcolor {
  position: absolute;
  background: linear-gradient(#f63e3b, #fff);
  /* background: #f63e3b; */
  height: 170px;
  width: 100%;
}

.pageview {
  min-height: 100%;
  background: #fff;
}

.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 40px;
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}

.wktime {
  padding: 0 20px;
}

.wktype {
  padding: 0 5px 5px;
}

.orginview .iconimg {
  width: 62%;
}

.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}

.wktype .iconimg {
  width: 65%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.grouptitle {
  text-indent: 5px;
  font-size: 18px;
  color: #231815;
  font-weight: 700;
}

.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}

.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fuwenben p {
  margin: 0 !important;
}
.orderName {
  position: absolute;
  color: #fff;
  font-size: 14px;
  margin: 10px 13px 0px;
  display: flex;
  align-items: center;
}
</style>